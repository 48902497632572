<template>
 <router-view></router-view>

</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>


<style>
.spinner-tbody {
    position: relative;
}

.spinner-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.td-custom-height{
    height: 1em;
}

.overflow-text{
  word-break: break-word;
  white-space: pre-line;
  overflow-wrap: break-word;
}
</style>
