
import api from "../../api"
export const state = {

    storesByCustomerList: [],
    storesByCustomerData: {},
    storesByCustomerErrorMsg: null,
    storesByCustomerSuccessMsg: null,
} 
export const mutations = {
 
    SET_STORES_BY_CUSTOMER_LIST(state, storesByCustomerList) {
        state.storesByCustomerList = storesByCustomerList
    },
    SET_STORES_BY_CUSTOMER_DATA(state, storesByCustomerData){
        state.storesByCustomerData = storesByCustomerData
    },
 
    SET_ERROR_MSG(state, storesByCustomerErrorMsg){
        state.storesByCustomerErrorMsg = storesByCustomerErrorMsg
    },
    SET_SUCCESS_MSG(state, storesByCustomerSuccessMsg){
        state.storesByCustomerSuccessMsg = storesByCustomerSuccessMsg
    },

}

export const getters = {
   getStoresByCustomerList: state => state.storesByCustomerList,
   storesByCustomerData: state => state.storesByCustomerData,
   storesByCustomerErrorMsg: state => state.storesByCustomerErrorMsg,
   storesByCustomerSuccessMsg: state => state.storesByCustomerSuccessMsg,
}

export const actions = {
    async fetchStoresByCustomerList({commit}, shopId,  page = 1){
        await api({requiresAuth: true}).get(`/shop/${shopId}/stores?page=${page}`)
            .then((result) => {
                const { data } = result;
                commit('SET_STORES_BY_CUSTOMER_LIST', data)
                
            })
            .catch(error => {
                console.error(error)
                
            })
    },

    async getStoresByCustomerData({commit}, {shopId: shopId, page: page = 1}){
        try {
            const result = await api({ requiresAuth: true }).get(`/shop/${shopId}/stores?page=${page}`);
            const { data } = result;
            commit('SET_STORES_BY_CUSTOMER_DATA', data);
          } catch (error) {
            console.error(error);
          }
  
    },
    

}