import { getFirebaseBackend } from '../../authUtils.js'
import api from "../../api";
export const state = {
    currentUser: localStorage.getItem('user'),
}

export const mutations = {
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue
        saveState('user', newValue)
    }
}

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.currentUser
    }
}

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    // eslint-disable-next-line no-unused-vars
    init({ state, dispatch }) {
        dispatch('validate')
    },

    // Logs in the current user.
   async logIn({ commit }, { email, password } = {}) {
    
   await api({ requiresAtuh: false })
    .post("/login", {
      email: email,
      password: password,
    })
    .then((result) => {
        console.log(result);
        commit('SET_CURRENT_USER', result.data.user);
        localStorage.setItem('token', result.data.authorization.token);
    });
    },

    // Logs out the current user.
   async logOut({ commit }) {
        await api
        .post("/logout", {

        })
        .then((result) => {
            console.log(result);
            commit('SET_CURRENT_USER', null);
            localStorage.setItem('token', null);
        });
      
    },

    // register the user
    register({ commit, dispatch, getters }, { username, email, password } = {}) {
        if (getters.loggedIn) return dispatch('validate')

        return getFirebaseBackend().registerUser(username, email, password).then((response) => {
            const user = response
            commit('SET_CURRENT_USER', user)
            return user
        });
    },

    // register the user
    // eslint-disable-next-line no-unused-vars
    resetPassword({ commit, dispatch, getters }, { email } = {}) {
        if (getters.loggedIn) return dispatch('validate')

        return getFirebaseBackend().forgetPassword(email).then((response) => {
            const message = response.data
            return message
        });
    },

    // Validates the current user's token and refreshes it
    // with new data from the API.
    // eslint-disable-next-line no-unused-vars
    validate({ commit, state }) {
        if (!state.currentUser) return Promise.resolve(null)
        const user = getFirebaseBackend().getAuthenticatedUser();
        commit('SET_CURRENT_USER', user)
        return user;
    },
}

// ===
// Private helpers
// ===

function saveState(key, state) {
    localStorage.setItem(key, JSON.stringify(state))
}
