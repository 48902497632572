import api from "../../api"
export const state = {
    areBrochuresLoading: false,
    brochuresList: [],
    brochuresData: {},
    brochureErrorMsg: null,
    brochureSuccessMsg: null,
    newBrochure: null,
    isSearchingBrochures: false, 
    searchBrochuresData: {},
    brochuresPage: null,
} 
export const mutations = {
    SET_LOADING(state, areBrochuresLoading){
        state.areBrochuresLoading = areBrochuresLoading
    },
    SET_BROCHURES_LIST(state, brochuresList) {
        state.brochuresList = brochuresList
    },
    SET_BROCHURES_DATA(state, brochuresData){
        state.brochuresData = brochuresData
    },
    ADD_BROCHURE(state, newBrochure){ 
        state.newBrochure = newBrochure
    },
    UPDATE_BROCHURE(state, updatedBrochure){
        state.brochuresList = state.brochuresList.map(brochure => 
            brochure.id === updatedBrochure.id ? updatedBrochure : brochure
        )
    },
    SET_ERROR_MSG(state, brochureErrorMsg){
        state.brochureErrorMsg = brochureErrorMsg
    },
    SET_SUCCESS_MSG(state, brochureSuccessMsg){
        state.brochureSuccessMsg = brochureSuccessMsg
    },

    DELETE_BROCHURE(state, brochureId){
        state.brochuresList = state.brochuresList.filter(brochure => brochure.id !== brochureId)
    },
    SET_IS_SEARCHING(state, isSearchingBrochures) {
        state.isSearchingBrochures = isSearchingBrochures;
    },

    SET_SEARCH_DATA(state, searchBrochuresData) {
        state.searchBrochuresData = searchBrochuresData;
    },

    SET_BROCHURES_PAGE(state, brochuresPage){
        state.brochuresPage = brochuresPage
    }
}

export const getters = {
   brochuresList: state => state.brochuresList,
   brochuresData: state => state.brochuresData,
   areBrochuresLoading: state => state.areBrochuresLoading,
   newBrochure: state => state.newBrochure,
   brochureErrorMsg: state => state.brochureErrorMsg,
   brochureSuccessMsg: state => state.brochureSuccessMsg,
   isSearchingBrochures: state => state.isSearchingBrochures,
   searchBrochuresData: state => state.searchBrochuresData
}

export const actions = {
    async fetchBrochuresList({commit}, page = 1){
        await api({requiresAuth: true}).get(`/brochures?page=${page}`)
            .then(response => {
                const { data } = response;
                commit('SET_BROCHURES_LIST', data)
                
            })
            .catch(error => {
                console.error(error)
                
            })
    },
    async getBrochuresData({commit}, page = 1){
        await api({requiresAuth: true}).get(`/brochures?page=${page}`)
        .then(response => {
            const {data}  = response; 
            commit('SET_BROCHURES_DATA', data)
            commit('SET_BROCHURES_PAGE', response.data.meta.current_page)
        })
        .catch(error => {
            console.error(error)
            
        })
    },
    
    async addBrochure({commit}, newBrochure){
      
        try {
            const response =   await api({requiresAuth: true}).post(`/brochures`, newBrochure, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            const { data } = response;
    
            commit('ADD_BROCHURE', data.data);
            commit('SET_ERROR_MSG', null);
            commit('SET_SUCCESS_MSG', 'Thank you for your submission');
    
     
        } catch (error) {
            commit('SET_ERROR_MSG', error.response.data.message);
            throw error;
        }
    },
    
    async updateBrochure({commit}, updateBrochure){
        await api({requiresAuth: true}).put(`/brochures/${updateBrochure.id}`, updateBrochure)
        .then(() => {
            commit('UPDATE_BROCHURE', updateBrochure);
            commit('SET_ERROR_MSG', null)
            commit('SET_SUCCESS_MSG', 'Brochure updated successfully')
        })
        .catch(error => {
            commit('SET_ERROR_MSG', error.response.data.message)
        })
    },
    
    async deleteBrochure({commit}, brochureId){
        await api({ requiresAuth: true }).delete(`/brochures/${brochureId}`)
        .then(() => {
            commit('DELETE_BROCHURE', brochureId);
            commit('SET_ERROR_MSG', null)
            commit('SET_SUCCESS_MSG', 'Brochure deleted successfully')
        })
        .catch(error =>{
            commit('SET_ERROR_MSG', error.response.data.message)
        })
    },
    async getSearchBrochuresData({ commit }, { page, searchDataObject }) {
        commit('SET_LOADING', true);
        commit('SET_IS_SEARCHING', true);

       commit('SET_SEARCH_DATA', searchDataObject);
      
        await api({ requiresAuth: true }).post(`/brochures/search?page=${page}`, searchDataObject)
          .then((response) => {
            const { data } = response;
            commit('SET_BROCHURES_DATA', data);
            commit('SET_ERROR_MSG', null); 
            commit('SET_BROCHURES_PAGE', response.data.meta.current_page);
          })
          .catch((error) => {
            commit('SET_ERROR_MSG', error.response.data.message);
          })
          .finally(() => {
            commit('SET_LOADING', false);
          });
    },

}