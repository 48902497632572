import api from "../../api"
export const state = {
    areCategoriesLoading: false,
    categoriesList: [],
    categoriesData: {},
    newCategory: null,
    categoryErrorMsg: null,
    categorySuccessMsg: null,
    isSearchingCategories: false,
    searchCategoriesData: {},
    categoriesPage: null,
} 
export const mutations = {
    SET_LOADING(state, areCategoriesLoading){
        state.areCategoriesLoading = areCategoriesLoading
    },
    SET_CATEGORIES_LIST(state, categoriesList) {
        state.categoriesList = categoriesList
    },
    SET_CATEGORIES_DATA(state, categoriesData){
        state.categoriesData = categoriesData
    },
    SET_IS_SEARCHING(state, isSearchingCategories) {
        state.isSearchingCategories = isSearchingCategories;
    },
    ADD_CATEGORY(state, newCategory){ 
        state.newCategory = newCategory
    },
    DELETE_CATEGORY(state, categoryId){
        state.categoriesList = state.categoriesList.filter(category => category.id !== categoryId)
    },
    UPDATE_CATEGORY(state, updatedCategory){
        state.categoriesList = state.categoriesList.map(category => 
            category.id === updatedCategory.id ? updatedCategory : category
        )
    },
    SET_ERROR_MSG(state, categoryErrorMsg){
        state.categoryErrorMsg = categoryErrorMsg
    },
    SET_SUCCESS_MSG(state, categorySuccessMsg){
        state.categorySuccessMsg = categorySuccessMsg
    }, 
    SET_SEARCH_DATA(state, searchCategoriesData) {
        state.searchCategoriesData = searchCategoriesData;
    },

    SET_CATEGORIES_PAGE(state, categoriesPage){
        state.categoriesPage = categoriesPage
    }
}

export const getters = {
   categoriesList: state => state.categoriesList,
   categoriesData: state => state.categoriesData,
   areCategoriesLoading: state => state.areCategoriesLoading,
   newCategory: state => state.newCategory,
   categoryErrorMsg: state => state.categoryErrorMsg,
   categorySuccessMsg: state => state.categorySuccessMsg,
   isSearchingCategories: state => state.isSearchingCategories,
   searchCategoriesData: state => state.searchCategoriesData,
   categoriesPage: state => state.categoriesPage
}

export const actions = {
    // async fetchCategoriesList({commit}, page = 1){
    //     commit('SET_LOADING', true);

    //     await api({requiresAuth: true}).get(`/categories?page=${page}`)
    //         .then(response => {
    //             const { data } = response;
    //             commit('SET_CATEGORIES_LIST', data);
    //             commit('SET_ERROR_MSG', null);
    //         })
    //         .catch(error => {
    //             commit('SET_ERROR_MSG', error.response.data.message) 
    //         })
    //         .finally(() => {
    //             commit('SET_LOADING', false);
    //         });
    // },

    // async getCategoriesData({commit}, page = 1){
    //     commit('SET_LOADING', true);

    //     await api({requiresAuth: true}).get(`/categories?page=${page}`)
    //     .then(response => {
    //         const {data}  = response; 
    //         commit('SET_CATEGORIES_DATA', data);
    //         commit('SET_ERROR_MSG', null);
    //         commit('SET_CATEGORIES_PAGE', response.data.meta.current_page);
    //     })
    //     .catch(error => {
    //         commit('SET_ERROR_MSG', error.response.data.message)
    //     })
    //     .finally(() => {
    //         commit('SET_LOADING', false);
    //     });
    // },

    async fetchCategoriesList({commit} ){
        commit('SET_LOADING', true);

        await api({requiresAuth: true}).get(`/categories`)
            .then(response => {
                const { data } = response;
                commit('SET_CATEGORIES_LIST', data);
                commit('SET_ERROR_MSG', null);
            })
            .catch(error => {
                commit('SET_ERROR_MSG', error.response.data.message) 
            })
            .finally(() => {
                commit('SET_LOADING', false);
            });
    },

    async getCategoriesData({commit} ){
        commit('SET_LOADING', true);

        await api({requiresAuth: true}).get(`/categories`)
        .then(response => {
            const {data}  = response; 
            commit('SET_CATEGORIES_DATA', data);
            commit('SET_ERROR_MSG', null); 
        })
        .catch(error => {
            commit('SET_ERROR_MSG', error.response.data.message)
        })
        .finally(() => {
            commit('SET_LOADING', false);
        });
    },
    
    async addCategory({commit}, newCategory){ 
        try {
            const response =   await api({requiresAuth: true}).post(`/categories`, newCategory);
            // , {
            //     headers: {
            //         'Content-Type': 'multipart/form-data',
            //         'Accept': 'application/json',
            //     },
            // }
        //);
            const { data } = response;
    
            commit('ADD_CATEGORY', data.data);
            commit('SET_ERROR_MSG', null);
            commit('SET_SUCCESS_MSG', 'Thank you for your submission');
    
     
        } catch (error) {
            commit('SET_ERROR_MSG', error.response.data.message); 
        }
    },
    
    async updateCategory({commit}, updateCategory){
        await api({requiresAuth: true}).post(`/categories/${updateCategory.id}?_method=PUT`, updateCategory
        // , {
        //     headers: {
        //         'Content-Type': 'multipart/form-data',
        //         'Accept': 'application/json',
        //     },
        // }
    )
        .then(() => {
            commit('UPDATE_CATEGORY', updateCategory);
            commit('SET_ERROR_MSG', null)
            commit('SET_SUCCESS_MSG', 'Category updated successfully')
        })
        .catch(error => {
            commit('SET_ERROR_MSG', error.response.data.message)
        })
    },
    
    async deleteCategory({commit}, categoryId){
        await api({ requiresAuth: true }).delete(`/categories/${categoryId}`)
        .then(() => {
            commit('DELETE_CATEGORY', categoryId);
            commit('SET_ERROR_MSG', null)
            commit('SET_SUCCESS_MSG', 'Category deleted successfully')
        })
        .catch(error =>{
            commit('SET_ERROR_MSG', error.response.data.message)
        })
    },
    
    async getSearchCategoriesData({ commit }, { page, searchDataObject }) {
        commit('SET_LOADING', true);
        commit('SET_IS_SEARCHING', true);

       commit('SET_SEARCH_DATA', searchDataObject);
      
        await api({ requiresAuth: true }).post(`/categories/search?page=${page}`, searchDataObject)
          .then((response) => {
            const { data } = response;
            commit('SET_CATEGORIES_DATA', data);
            commit('SET_ERROR_MSG', null); 
            commit('SET_CATEGORIES_PAGE', response.data.meta.current_page);
          })
          .catch((error) => {
            commit('SET_ERROR_MSG', error.response.data.message);
          })
          .finally(() => {
            commit('SET_LOADING', false);
          });
    },
}