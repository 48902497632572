import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
    meta: {
      title: "Register",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "Dashboard" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/",
    name: "Dashboard",
    meta: {
      title: "Dashboard",
      authRequired: true,
    },
    component: () => import("../views/dashboard/Dashboard.vue"),
  },
 
  {
    path: "/customers",
    name: "Customers",
    meta: {
      title: "Customers",
      authRequired: true,
    },
    component: () => import("../views/customers/Customer.vue"),
  },
  {
    path: "/customers/:id",
    name: "Edit Customers",
    meta: {
      title: "Edit Customer",
    },
    component: () => import("../views/customers/EditCustomer.vue"),
  },
  {
    path: "/brochures",
    name: "Brochures",
    meta: {
      title: "Brochures",
    },
    component: () => import("../views/brochures/Brochure.vue"),
  },
  {
    path: "/brochures/:id",
    name: "Edit Brochures",
    meta: {
      title: "Edit Brochures",
    },
    component: () => import("../views/brochures/EditBrochure.vue"),
  },
  {
    path: "/banners",
    name: "Banners",
    meta: {
      title: "Banners",
    },
    component: () => import("../views/banners/Banners.vue"),
  },
  {
    path: "/categories",
    name: "Categories",
    meta: {
      title: "Categories",
    },
    component: () => import("../views/categories/Categories.vue"),
  },
  {
    path: "/cities",
    name: "Cities",
    meta: {
      title: "Cities",
    },
    component: () => import("../views/city/City.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout", authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        localStorage.clear();
        sessionStorage.clear();
        next();
      },
    },
    component: () => import("../views/auth/logout/basic")
  }
];