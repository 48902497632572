import api from "../../api"
export const state = {
    areBannersLoading: false,
    bannersList: [],
    bannersData: {},
    newBanner: null,
    bannerErrorMsg: null,
    bannerSuccessMsg: null,
    isSearchingBanners: false,
    searchBannersData: {},
    bannersPage: null,
} 
export const mutations = {
    SET_LOADING(state, areBannersLoading){
        state.areBannersLoading = areBannersLoading
    },
    SET_BANNERS_LIST(state, bannersList) {
        state.bannersList = bannersList
    },
    SET_BANNERS_DATA(state, bannersData){
        state.bannersData = bannersData
    },
    SET_IS_SEARCHING(state, isSearchingBanners) {
        state.isSearchingBanners = isSearchingBanners;
    },
    ADD_BANNER(state, newBanner){ 
        state.newBanner = newBanner
    },
    DELETE_BANNER(state, bannerId){
        state.bannersList = state.bannersList.filter(banner => banner.id !== bannerId)
    },
    UPDATE_BANNER(state, updatedBanner){
        state.bannersList = state.bannersList.map(banner => 
            banner.id === updatedBanner.id ? updatedBanner : banner
        )
    },
    SET_ERROR_MSG(state, bannerErrorMsg){
        state.bannerErrorMsg = bannerErrorMsg
    },
    SET_SUCCESS_MSG(state, bannerSuccessMsg){
        state.bannerSuccessMsg = bannerSuccessMsg
    }, 
    SET_SEARCH_DATA(state, searchBannersData) {
        state.searchBannersData = searchBannersData;
    },

    SET_BANNERS_PAGE(state, bannersPage){
        state.bannersPage = bannersPage
    }
}

export const getters = {
   bannersList: state => state.bannersList,
   bannersData: state => state.bannersData,
   areBannersLoading: state => state.areBannersLoading,
   newBanner: state => state.newBanner,
   bannerErrorMsg: state => state.bannerErrorMsg,
   bannerSuccessMsg: state => state.bannerSuccessMsg,
   isSearchingBanners: state => state.isSearchingBanners,
   searchBannersData: state => state.searchBannersData,
   bannersPage: state => state.bannersPage
}

export const actions = {
    async fetchBannersList({commit}, page = 1){
        commit('SET_LOADING', true);

        await api({requiresAuth: true}).get(`/banners?page=${page}`)
            .then(response => {
                const { data } = response;
                commit('SET_BANNERS_LIST', data);
                commit('SET_ERROR_MSG', null);
            })
            .catch(error => {
                commit('SET_ERROR_MSG', error.response.data.message) 
            })
            .finally(() => {
                commit('SET_LOADING', false);
            });
    },

    async getBannersData({commit}, page = 1){
        commit('SET_LOADING', true);

        await api({requiresAuth: true}).get(`/banners?page=${page}`)
        .then(response => {
            const {data}  = response; 
            commit('SET_BANNERS_DATA', data);
            commit('SET_ERROR_MSG', null);
            commit('SET_BANNERS_PAGE', response.data.meta.current_page);
        })
        .catch(error => {
            commit('SET_ERROR_MSG', error.response.data.message)
        })
        .finally(() => {
            commit('SET_LOADING', false);
        });
    },
    
    async addBanner({commit}, newBanner){ 
        try {
            const response =   await api({requiresAuth: true}).post(`/banners`, newBanner, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json',
                },
            });
            const { data } = response;
    
            commit('ADD_BANNER', data.data);
            commit('SET_ERROR_MSG', null);
            commit('SET_SUCCESS_MSG', 'Thank you for your submission');
    
     
        } catch (error) {
            commit('SET_ERROR_MSG', error.response.data.message); 
        }
    },
    
    async updateBanner({commit}, updateBanner){
        await api({requiresAuth: true}).post(`/banners/${updateBanner.id}?_method=PUT`, updateBanner, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
            },
        })
        .then(() => {
            commit('UPDATE_BANNER', updateBanner);
            commit('SET_ERROR_MSG', null)
            commit('SET_SUCCESS_MSG', 'Banner updated successfully')
        })
        .catch(error => {
            commit('SET_ERROR_MSG', error.response.data.message)
        })
    },
    
    async deleteBanner({commit}, bannerId){
        await api({ requiresAuth: true }).delete(`/banners/${bannerId}`)
        .then(() => {
            commit('DELETE_BANNER', bannerId);
            commit('SET_ERROR_MSG', null)
            commit('SET_SUCCESS_MSG', 'Banner deleted successfully')
        })
        .catch(error =>{
            commit('SET_ERROR_MSG', error.response.data.message)
        })
    },
    
    async getSearchBannersData({ commit }, { page, searchDataObject }) {
        commit('SET_LOADING', true);
        commit('SET_IS_SEARCHING', true);

       commit('SET_SEARCH_DATA', searchDataObject);
      
        await api({ requiresAuth: true }).post(`/banners/search?page=${page}`, searchDataObject)
          .then((response) => {
            const { data } = response;
            commit('SET_BANNERS_DATA', data);
            commit('SET_ERROR_MSG', null); 
            commit('SET_BANNERS_PAGE', response.data.meta.current_page);
          })
          .catch((error) => {
            commit('SET_ERROR_MSG', error.response.data.message);
          })
          .finally(() => {
            commit('SET_LOADING', false);
          });
    },
}