import api from "../../api"
export const state = {
    customersList: [],
    customersData: {},
    customerErrorMsg: null,
    customerSuccessMsg: null,
    newCustomer: null,
    isSearchingCustomers: false, 
    searchCustomersData: {},
    customersPage: null,
}
export const mutations = {

    SET_CUSTOMERS_LIST(state, customersList) {
        state.customersList = customersList
    },
    SET_CUSTOMERS_DATA(state, customersData) {
        state.customersData = customersData
    },
    ADD_CUSTOMER(state, newCustomer) {
        state.newCustomer = newCustomer
    },
    UPDATE_CUSTOMER(state, updatedCustomer) {
        state.customersList = state.customersList.map(customer =>
            customer.id === updatedCustomer.id ? updatedCustomer : customer
        )
    },
    SET_ERROR_MSG(state, customerErrorMsg) {
        state.customerErrorMsg = customerErrorMsg
    },
    SET_SUCCESS_MSG(state, customerSuccessMsg) {
        state.customerSuccessMsg = customerSuccessMsg
    },

    DELETE_CUSTOMER(state, customerId) {
        state.customersList = state.customersList.filter(customer => customer.id !== customerId)
    },
    SET_CUSTOMER_IS_SEARCHING(state, isSearchingCustomers) {
        state.isSearchingCustomers = isSearchingCustomers;
    },

    SET_CUSTOMER_SEARCH_DATA(state, searchCustomersData) {
        state.searchCustomersData = searchCustomersData;
    },

    SET_CUSTOMERS_PAGE(state, customersPage){
        state.customersPage = customersPage
    }
}

export const getters = {
    getcustomersList: state => state.customersList,
    customersData: state => state.customersData,
    newCustomer: state => state.newCustomer,
    customerErrorMsg: state => state.customerErrorMsg,
    customerSuccessMsg: state => state.customerSuccessMsg,
    isSearchingCustomers: state => state.isSearchingCustomers,
    searchCustomersData: state => state.searchCustomersData,
}

export const actions = {
    async fetchCustomersList({ commit }, page = 1) {
        await api({ requiresAuth: true }).get(`/shops?page=${page}`)
            .then((result) => {
                const { data } = result;
                commit('SET_CUSTOMERS_LIST', data.shops)

            })
            .catch(error => {
                console.error(error)

            })
    },
    async getCustomersData({ commit }, page = 1) {
        await api({ requiresAuth: true }).get(`/shops?page=${page}`)
            .then((result) => {
                const { data } = result;
                commit('SET_CUSTOMERS_DATA', data.shops)
                commit('SET_CUSTOMERS_PAGE', result.data.shops.current_page)
            })
            .catch(error => {
                console.error(error)

            })
    },
    async addCustomer({ commit }, newCustomer) {
        try {
            const response = await api({ requiresAuth: true }).post(`/shops`, newCustomer, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            const { data } = response;

            commit('ADD_CUSTOMER', data.shops);
            commit('SET_ERROR_MSG', null);
            commit('SET_SUCCESS_MSG', 'Thank you for your submission');


        } catch (error) {
            commit('SET_ERROR_MSG', error.response.data.message);
            throw error;
        }
    },

    async updateCustomer({ commit }, updateCustomer) {
        await api({ requiresAuth: true }).post(`/shops/${updateCustomer.id}?_method=PUT`, updateCustomer,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json',
                },
            }
        )
            .then(() => {
                commit('UPDATE_CUSTOMER', updateCustomer);
                commit('SET_ERROR_MSG', null)
                commit('SET_SUCCESS_MSG', 'Customer updated successfully')
                console.log(this.customerErrorMsg)
            })
            .catch(error => {
                console.log(this.customerErrorMsg)
                commit('SET_ERROR_MSG', error.response.data.message)
            })
    },

    async deleteCustomer({ commit }, customerId) {
        await api({ requiresAuth: true }).delete(`/shops/${customerId}`)
            .then(() => {
                commit('DELETE_CUSTOMER', customerId);
                commit('SET_ERROR_MSG', null)
                commit('SET_SUCCESS_MSG', 'Customer deleted successfully')
            })
            .catch(error => {
                commit('SET_ERROR_MSG', error.response.data.message)
            })
    },
    async getSearchCustomersData({ commit }, { page, searchDataObject }) {
        // commit('SET_LOADING', true);
        commit('SET_CUSTOMER_IS_SEARCHING', true);

       commit('SET_CUSTOMER_SEARCH_DATA', searchDataObject);
      
        await api({ requiresAuth: true }).post(`/shops/search?page=${page}`, searchDataObject)
          .then((response) => {
            const { data } = response;
            commit('SET_CUSTOMERS_DATA', data.shops);
            commit('SET_ERROR_MSG', null); 
            commit('SET_CUSTOMERS_PAGE', response.data.shops.current_page);
          })
          .catch((error) => {
            commit('SET_ERROR_MSG', error.response.data.message);
          })
        //   .finally(() => {
        //     commit('SET_LOADING', false);
        //   });
    },

}